import { defineI18nConfig } from '#i18n'

export default defineI18nConfig(() => ({
    legacy: false,
    messages: {
        'en-US': {
            app: {
                nameShort: 'RPHQ',
                nameLong: 'Redpoint HQ'
            },
            summary: {
                welcome: 'Welcome {name}',
                storeCredit: 'Store Credit',
                accountBalance: 'Account Balance',
                makeAccountPayment: 'Make Account Payment',
                accountAlerts: 'Account Alerts',
                accountAlertsEmpty: 'Everything looks good!',
                recentOrders: 'Recent Orders',
                andOtherItems: 'and 1 other item | and {n} other items',
                activeBenefits: 'Active Benefits'
            },
            benefit: 'Benefit | Benefits',
            benefits: {
                name: 'Name',
                quantity: 'Quantity',
                unlimited: 'Unlimited'
            },
            membership: 'Membership | Memberships',
            contracts: {
                status: 'Status',
                draft: 'Draft',
                confirmed: 'Confirmed',
                active: 'Active',
                frozen: 'Frozen',
                terminated: 'Terminated',
                concluded: 'Concluded'
            },
            booking: 'Booking | Bookings',
            facility: 'Facility | Facilities',
            homeFacility: 'Home Facility',
            edit: 'Edit',
            continue: 'Continue',
            showMore: 'Show More...',
            signInOrRegister: 'Sign In / Register',
            codePrompt:
                'Please enter your phone number or email address to receive a verification code.',
            codeError:
                'Please enter either a phone number or email to receive a verification code.',
            codeEnter: 'Please enter the code sent to:',
            codeVerify: 'Verify',
            codeInvalid:
                'Invalid code entered. Please check the code and try again.',
            rememberMe: 'Remember Me',
            continueAsGuest: 'Continue as Guest',
            signIn: 'Sign In',
            passCaptcha:
                'To continue as a guest, please complete the following captcha:',
            signInComplete: 'Sign In Complete',
            signInFlow: {
                chooseFacility: 'Choose a Facility:',
                foundCustomers:
                    'We found some customers that match your verified login and/or date of birth. Please choose the correct one or click <span class="font-bold">I Don\'t See Myself</span> to create a new one.',
                name: 'Name',
                homeFacility: 'Home Facility',
                lastActivity: 'Last Activity',
                thisIsMe: 'This is Me',
                iDoNotSeeMyself: "I Don't See Myself",
                noAccountFound:
                    'We were unable to find an existing online account for you. To create an online account we just need some basic information about you:',
                seeStaff:
                    'If you have an existing customer record with us, our staff can attach this to your online account when we see you next!',
                guardianNote:
                    '<span class="text-red-600 font-semibold">IMPORTANT:</span> Please use YOUR OWN NAME to create your account even if you are a parent/guardian. You will be able to add other participants to your account when completing the waiver or booking.',
                fullName: 'Full Name:'
            },
            next: 'Next',
            purchaseContract: {
                viewCart: 'View Cart',
                duplicateAgreementWarning:
                    'You already have a contract for this agreement in your cart',
                editAgreement: 'Edit Agreement',
                selectParticipants: 'Select Participants',
                changeParticipants: 'Change Participants',
                participants: 'Participants',
                watchSafetyVideo: 'Watch Safety Video',
                alreadyWatched: "I've Already Watched",
                agreementMinimumBookingLimit:
                    'Agreement must have at least {minimumEnrollments} participants',
                maximumBookingLimit:
                    'Maximum participants allowed is {maximumEnrollments}',
                maxEnrollmentType: 'Max: {quantity} {enrollmentTypeName}',
                minEnrollmentType: 'Min: {quantity} {enrollmentTypeName}',
                planDetails: '{planType} Details',
                selectExistingHouseholdMemberOrAddNew:
                    '\u00A0\u00A0—\u00A0\u00A0Select from existing household members or add new ones',
                noParticipantDetailsRequired:
                    'No details required at this time. Please review the pricing if applicable and complete your booking!',
                pricing: 'Pricing',
                completeBooking: 'Complete Booking',
                saveChanges: 'Save Changes',
                addToCart: 'Add to Cart',
                checkoutNow: 'Checkout Now',
                timeExpiredWarning:
                    "We're sorry but the time allotted to finish your purchase has expired. If you still wish to purchase this item, please click Change Order to try again.",
                contractsNotReadyForPurchaseWarning:
                    'You have {count} contract(s) not ready for purchase! Please edit or remove these contracts and try again.',
                pleaseFixErrorsAbove: 'Please fix the errors above',
                customerInformation: 'Customer Information',
                selectExistingParticipant: 'Select existing participant',
                selectFromExistingHousehold:
                    'Select from existing household...',
                selectEnrollmentType: 'Select {enrollmentType}',
                or: '— OR —',
                purchasingForSomeoneElse: 'Purchasing for someone else?',
                signingForSomeoneElse: 'Signing for someone else?',
                addNewCustomer: 'Add New Customer',
                notViewer: 'Not {name}?',
                signInToYourProfile: 'Sign in to your profile',
                fillOutDateOfBirth: 'Please fill out Date of Birth',
                futureDateOfBirthWarning:
                    'Date of Birth cannot be in the future',
                ageInRangeRequired: 'Age {minimum} to {maximum} required.',
                ageAtLeastRequired: 'Age at least {minimum} required.',
                ageLessThanRequired: 'Age less than {maximum} required.',
                onlyViewerCanBeEnrollmentType:
                    'Only {viewer} can be the {enrollmentType}',
                saveParticipant: 'Save participant',
                selectFromParticipants: 'Select from participants...',
                additionalInformation: 'Additional Information',
                selectAnOption: 'Select an option...',
                invalidParticipantsPricing:
                    'The selected combination of participants is invalid. Please choose a different combination.',
                documentSection: {
                    somethingWrong:
                        'Something went wrong. There is nothing here to agree to. Please contact the facility to resolve the issue.',
                    submitAgreement: 'Submit Agreement',
                    payNow: 'Pay Now',
                    completeDocument: 'Complete Document',
                    documentCompleted: 'Document Completed',
                    termsAndConditions: 'Terms and Conditions',
                    clickToSign: 'Click To Sign',
                    changeSignature: 'Change Signature',
                    signature: 'Signature',
                    clickToInitial: 'Click To Initial',
                    changeInitials: 'Change Initials',
                    initials: 'Initials',
                    signatureRequiredWarning: 'Complete required signature.',
                    initialsRequiredWarning: 'Complete required initials.',
                    signatureHelp:
                        'Use your mouse or finger to enter your signature above. Click OK to save.',
                    initialsHelp:
                        'Use your mouse or finger to enter your initials above. Click OK to save.'
                }
            },
            customerInformation: {
                firstName: 'First Name',
                twoOrMoreCharactersRequired: '2 or more characters required',
                middleNameOptional: 'Middle Name (optional)',
                middleName: 'Middle Name',
                lastName: 'Last Name',
                dateOfBirth: 'Date of Birth',
                age: '{age} years old',
                customerInformationSameAs: 'Customer information is same as:',
                emailAddress: 'Email Address',
                emailRequiredWarning: 'Please enter an email address above',
                invalidEmailWarning: 'Please enter a valid email address',
                mobilePhone: 'Mobile Phone',
                phoneNumber: 'Phone Number',
                invalidMobilePhoneWarning: 'Please enter a valid phone number',
                customerMustBeTheEnrollmentType:
                    'Customer must be the {enrollmentType} participant'
            },
            dropdownDateInput: {
                month: 'Month',
                monthRequired: 'Month Required',
                day: 'Day',
                dayRequired: 'Day Required',
                year: 'Year',
                yearRequired: 'Year Required',
                january: 'January',
                february: 'February',
                march: 'March',
                april: 'April',
                may: 'May',
                june: 'June',
                july: 'July',
                august: 'August',
                september: 'September',
                october: 'October',
                november: 'November',
                december: 'December'
            },
            address: {
                address: 'Address',
                displayCountry: 'Country: {country}',
                displayPostal: 'Postal: {postal}',
                countryAndPostalCode: 'Country and Postal Code',
                country: 'Country',
                invalidCountryWarning: 'Please enter a valid country',
                postalCode: 'Postal Code',
                invalidPostalCodeWarning: 'Please enter a valid postal code',
                lineOne: 'Address Line 1',
                streetAddressRequired: 'Please enter a street address',
                lineTwo: 'Address Line 2',
                city: 'City',
                cityRequired: 'Please enter a city',
                state: 'State',
                stateRequired: 'Please enter a state',
                countryCodeLengthWarning:
                    'Country code must be less than 64 characters',
                postalCodeLengthWarning:
                    'Postal code must be 64 characters or less',
                lineOneLengthWarning:
                    'Address line 1 must be 128 character or less',
                lineTwoLengthWarning:
                    'Address line 2 must be 128 character or less',
                cityLengthWarning: 'City must be 64 character or less',
                stateLengthWarning: 'State must be 64 character or less'
            },
            oops: 'Oops!',
            somethingWrongWarning:
                'Whoops something went wrong here. Please try again and contact the facility if the issue continues.',
            headsUp: 'Heads Up!',
            yes: 'Yes',
            no: 'No',
            ok: 'Ok',
            cancel: 'Cancel',
            done: 'Done',
            billing: {
                perDay: '/day',
                perWeek: '/week',
                perFortnight: '/fortnight',
                perMonth: '/month',
                perQuarter: '/quarter',
                perYear: '/year'
            },
            checkout: {
                subtotal: 'Subtotal',
                inclusiveTax: 'Inclusive Tax',
                tax: 'Tax',
                total: 'Total'
            },
            thankYouForSubmission: 'Thank you for your submission!'
        },
        'es-ES': {
            // app: {
            //     nameShort: 'RPHQ',
            //     nameLong: 'Redpoint HQ'
            // },
            // summary: {
            //     welcome: 'Welcome {name}',
            //     storeCredit: 'Store Credit',
            //     accountBalance: 'Account Balance',
            //     makeAccountPayment: 'Make Account Payment',
            //     accountAlerts: 'Account Alerts',
            //     accountAlertsEmpty: 'Everything looks good!',
            //     recentOrders: 'Recent Orders',
            //     andOtherItems: 'and 1 other item | and {n} other items',
            //     activeBenefits: 'Active Benefits'
            // },
            // benefit: 'Benefit | Benefits',
            // benefits: {
            //     name: 'Name',
            //     quantity: 'Quantity',
            //     unlimited: 'Unlimited'
            // },
            // membership: 'Membership | Memberships',
            // contracts: {
            //     status: 'Status',
            //     draft: 'Draft',
            //     confirmed: 'Confirmed',
            //     active: 'Active',
            //     frozen: 'Frozen',
            //     terminated: 'Terminated',
            //     concluded: 'Concluded'
            // },
            // booking: 'Booking | Bookings',
            // facility: 'Facility | Facilities',
            // homeFacility: 'Home Facility',
            // edit: 'Edit',
            continue: 'Continuar',
            showMore: 'Mostrar Más',
            // signInOrRegister: 'Sign In / Register',
            // codePrompt:
            //     'Please enter your phone number or email address to receive a verification code.',
            // codeError:
            //     'Please enter either a phone number or email to receive a verification code.',
            // codeEnter: 'Please enter the code sent to:',
            // codeVerify: 'Verify',
            // codeInvalid:
            //     'Invalid code entered. Please check the code and try again.',
            // rememberMe: 'Remember Me',
            // continueAsGuest: 'Continue as Guest',
            // signIn: 'Sign In',
            // passCaptcha:
            //     'To continue as a guest, please complete the following captcha:',
            // signInComplete: 'Sign In Complete',
            // signInFlow: {
            //     chooseFacility: 'Choose a Facility:',
            //     foundCustomers:
            //         'We found some customers that match your verified login and/or date of birth. Please choose the correct one or click <span class="font-bold">I Don\'t See Myself</span> to create a new one.',
            //     name: 'Name',
            //     homeFacility: 'Home Facility',
            //     lastActivity: 'Last Activity',
            //     thisIsMe: 'This is Me',
            //     iDoNotSeeMyself: "I Don't See Myself",
            //     noAccountFound:
            //         'We were unable to find an existing online account for you. To create an online account we just need some basic information about you:',
            //     seeStaff:
            //         'If you have an existing customer record with us, our staff can attach this to your online account when we see you next!',
            //     guardianNote:
            //         '<span class="text-red-600 font-semibold">IMPORTANT:</span> Please use YOUR OWN NAME to create your account even if you are a parent/guardian. You will be able to add other participants to your account when completing the waiver or booking.',
            //     fullName: 'Full Name:'
            // },
            // next: 'Next',
            purchaseContract: {
                // viewCart: 'View Cart',
                // duplicateAgreementWarning: 'You already have a contract for this agreement in your cart',
                editAgreement: 'Editar acuerdo',
                selectParticipants: 'Seleccionar participantes',
                changeParticipants: 'Cambiar participantes',
                participants: 'Participantes',
                watchSafetyVideo: 'Ver vídeo de seguridad',
                alreadyWatched: 'Ya he visto',
                agreementMinimumBookingLimit:
                    'El acuerdo debe tener al menos {minimumEnrollments} participantes',
                maximumBookingLimit:
                    'El máximo de participantes permitido es {maximumEnrollments}',
                maxEnrollmentType: 'Máximo: {quantity} {enrollmentTypeName}',
                minEnrollmentType: 'Minimo: {quantity} {enrollmentTypeName}',
                planDetails: 'Detalles',
                selectExistingHouseholdMemberOrAddNew:
                    '\u00A0\u00A0—\u00A0\u00A0Seleccione los miembros existentes del hogar o agregue otros nuevos',
                noParticipantDetailsRequired:
                    'No se requieren detalles en este momento. ¡Revise el precio (si corresponde) y complete su reserva!',
                pricing: 'Precio',
                // completeBooking: 'Complete Booking',
                // saveChanges: 'Save Changes',
                // addToCart: 'Add to Cart',
                // checkoutNow: 'Checkout Now',
                // timeExpiredWarning:
                //     "We're sorry but the time allotted to finish your purchase has expired. If you still wish to purchase this item, please click Change Order to try again.",
                // contractsNotReadyForPurchaseWarning:
                //     'You have {count} contract(s) not ready for purchase! Please edit or remove these contracts and try again.',
                pleaseFixErrorsAbove:
                    'Por favor corrija los errores anteriores',
                customerInformation: 'Información del cliente',
                selectExistingParticipant: 'Seleccionar participante existente',
                selectFromExistingHousehold:
                    'Seleccione de un hogar existente...',
                selectEnrollmentType: 'Seleccionar {enrollmentType}',
                // or: '— OR —',
                purchasingForSomeoneElse: 'Purchasing for someone else?',
                signingForSomeoneElse: '¿Firmando para otra persona?',
                addNewCustomer: 'Agregar un nuevo cliente',
                notViewer: 'No eres {name}',
                signInToYourProfile: 'Inicia sesión en tu perfil',
                fillOutDateOfBirth: 'Por favor complete la fecha de nacimiento',
                futureDateOfBirthWarning:
                    'La fecha de nacimiento no puede ser en el futuro.',
                ageInRangeRequired: 'Edad requerida de {mínima} a {máxima}.',
                // ageAtLeastRequired: 'Age at least {minimum} required.',
                // ageLessThanRequired: 'Age less than {maximum} required.',
                // onlyViewerCanBeEnrollmentType:
                //     'Only {viewer} can be the {enrollmentType}',
                // saveParticipant: 'Save participant',
                // selectFromParticipants: 'Select from participants...',
                additionalInformation: 'Mas Informatcion',
                selectAnOption: 'Seleccione una opción',
                invalidParticipantsPricing:
                    'La combinación seleccionada de participantes no es válida. Elija una combinación diferente.',
                documentSection: {
                    somethingWrong:
                        'Algo salió mal. Aquí no hay nada que aceptar. Comuníquese con las instalaciones para resolver el problema.',
                    submitAgreement: 'Acepte este documento',
                    payNow: 'Paga ahora',
                    completeDocument: 'Acepte este documento',
                    documentCompleted: 'Documento completado',
                    termsAndConditions: 'Términos y condiciones',
                    clickToSign: 'Haga clic para firmar',
                    changeSignature: 'Cambiar firma',
                    signature: 'Firma',
                    clickToInitial: 'Haga clic para poner sus iniciales',
                    changeInitials: 'Cambiar iniciales',
                    initials: 'Iniciales',
                    signatureRequiredWarning: 'Complete la firma requerida.',
                    initialsRequiredWarning:
                        'Complete las iniciales requeridas.',
                    signatureHelp:
                        'Utilice el mouse o el dedo para ingresar su firma arriba. Haga clic en Aceptar para guardar.',
                    initialsHelp:
                        'Utilice el mouse o el dedo para ingresar sus iniciales arriba. Haga clic en Aceptar para guardar.'
                }
            },
            customerInformation: {
                firstName: 'Nombre',
                twoOrMoreCharactersRequired: 'Se requieren 2 o más caracteres',
                middleNameOptional: 'Segundo Nombre',
                middleName: 'Segundo Nombre',
                lastName: 'Apellido',
                dateOfBirth: 'Fecha de nacimiento',
                age: '{age} años',
                customerInformationSameAs:
                    'La información del cliente es la misma que:',
                emailAddress: 'Correo Electrónico',
                emailRequiredWarning:
                    'Por favor ingrese una dirección de correo electrónico arriba',
                invalidEmailWarning:
                    'Por favor, introduce una dirección de correo electrónico válida',
                mobilePhone: 'Teléfono móvil',
                phoneNumber: 'Número de Teléfono',
                invalidMobilePhoneWarning:
                    'Por favor ingresa un número de teléfono válido',
                customerMustBeTheEnrollmentType:
                    'El cliente debe ser el/la participante {enrollmentType}'
            },
            dropdownDateInput: {
                month: 'Mes',
                monthRequired: 'Mes requerido',
                day: 'Día',
                dayRequired: 'Día requerido',
                year: 'Año',
                yearRequired: 'Año requerido',
                january: 'Enero',
                february: 'Febrero',
                march: 'Marzo',
                april: 'Abril',
                may: 'Mayo',
                june: 'Junio',
                july: 'Julio',
                august: 'Agosto',
                september: 'Septiembre',
                october: 'Octubre',
                november: 'Noviembre',
                december: 'Diciembre'
            },
            address: {
                address: 'Dirección',
                displayCountry: 'País: {country}',
                displayPostal: 'Postal: {postal}',
                countryAndPostalCode: 'País y Código Postal',
                country: 'País',
                invalidCountryWarning: 'Por favor ingresa un país válido',
                postalCode: 'Código Postal',
                invalidPostalCodeWarning:
                    'Por favor introduce un código postal válido',
                lineOne: 'Dirección Línea 1',
                streetAddressRequired: 'Por favor ingrese una dirección postal',
                lineTwo: 'Dirección Línea 2',
                city: 'Ciudad',
                cityRequired: 'Por favor ingresa una ciudad',
                state: 'Estado',
                stateRequired: 'Por favor ingresa un estado',
                countryCodeLengthWarning:
                    'El código de país debe tener menos de 64 caracteres.',
                postalCodeLengthWarning:
                    'El código postal debe tener 64 caracteres o menos.',
                lineOneLengthWarning:
                    'La línea de dirección 1 debe tener 128 caracteres o menos',
                lineTwoLengthWarning:
                    'La línea de dirección 2 debe tener 128 caracteres o menos',
                cityLengthWarning:
                    'La ciudad debe tener 64 caracteres o menos.',
                stateLengthWarning:
                    'El estado debe tener 64 caracteres o menos.'
            },
            oops: '¡Ups!',
            somethingWrongWarning: 'Perdon.  Algo salió mal aquí',
            headsUp: '¡Aviso!',
            yes: 'Sí',
            no: 'No',
            ok: 'Ok',
            cancel: 'Cancelar',
            done: 'Terminado',
            billing: {
                perDay: '/dia',
                perWeek: '/semana',
                // perFortnight: '/fortnight',
                perMonth: '/mes',
                // perQuarter: '/quarter',
                perYear: '/año'
            }
            // checkout: {
            //     subtotal: 'Subtotal',
            //     inclusiveTax: 'Inclusive Tax',
            //     tax: 'Tax',
            //     total: 'Total'
            // },
            // thankYouForSubmission: 'Thank you for your submission!'
        }
    },
    fallbackLocale: 'en-US'
}))
